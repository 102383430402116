/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component, jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import BrandsList from '../BrandsList';
import { OVERLAY_LAYOUT } from '../BrandsList/BrandList.config';

import './BrandMenuOverlay.style';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/BrandMenuOverlay/Component */
export class BrandMenuOverlayComponent extends PureComponent {
    static propTypes = {
        isVisible: PropTypes.bool.isRequired,
        isDisplayLast: PropTypes.bool.isRequired
    };

    stopPropagation(e) {
        e.stopPropagation();
    }

    render() {
        const { isVisible, isDisplayLast } = this.props;

        return (
            <div
              block="BrandMenuOverlay"
              mods={ {
                  isVisible,
                  isDisplayLast
              } }
              onClick={ this.stopPropagation }
            >
                <BrandsList layout={ OVERLAY_LAYOUT } />
            </div>
        );
    }
}

export default BrandMenuOverlayComponent;
