import TextPlaceholder from 'Component/TextPlaceholder';

const renderCategoryText = (args, callback, instance) => {
    const {
        category: { name, category_seo_name },
        isCurrentCategoryLoaded,
        useCategorySeoName
    } = instance.props;

    const categoryName = useCategorySeoName ? category_seo_name || name : name;

    if (isCurrentCategoryLoaded) {
        return (
            <TextPlaceholder content={ categoryName } />
        );
    }

    return (
        <TextPlaceholder />
    );
};

export default {
    'Component/CategoryDetails/Component': {
        'member-function': {
            renderCategoryText
        }
    }
};
