import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

/** @namespace Scandipwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
}

export default ProductAttributeValueComponent;
