/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyShopbyBrand/Query/BrandsSlider/Query/BrandsSliderQuery */
export class BrandsSliderQuery {
    getQuery() {
        return new Field('ambrandslider')
            .addFieldList(this.getSliderFields());
    }

    getSliderFields() {
        return [
            'autoplay',
            'autoplay_delay',
            'buttons_show',
            'image_height',
            'image_width',
            'infinity_loop',
            this.getItemsFields(),
            'items_number',
            'pagination_clickable',
            'pagination_show',
            'show_label',
            'simulate_touch',
            'slider_header_color',
            'slider_title',
            'slider_title_color',
            'slider_width'
        ];
    }

    getItemsFields() {
        return new Field('items')
            .addFieldList(this.getSliderItemFields());
    }

    getSliderItemFields() {
        return [
            'alt',
            'brandId',
            'img',
            'label',
            'position',
            'url'
        ];
    }
}

export default new BrandsSliderQuery();
