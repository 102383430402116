/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace Scandipwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    /**
     * Overridden to change the order in which the price is displayed:
     * the old price now follows the final price and remove currency schema
     */
    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label
        } = this.props;

        return (
            <>
                { this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
                { this.renderOldPrice() }
            </>
        );
    }

    /**
     * Overridden to approximate price
     */
    getCurrentPriceSchema() {
        const {
            isSchemaRequired,
            variantsCount,
            price: {
                finalPrice: {
                    value: contentPrice = 0
                } = {}
            } = {}
        } = this.props;

        const priceApprox = contentPrice.toFixed(2);

        if (variantsCount > 1) {
            return isSchemaRequired ? { itemProp: 'lowPrice', content: priceApprox } : {};
        }

        return isSchemaRequired ? { itemProp: 'price', content: priceApprox } : {};
    }

    /**
     * Overridden to remove redundant highPrice
     */
    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted
                } = {}
            } = {},
            discountPercentage
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
            >
                { originalPriceFormatted }
            </del>
        );
    }

    /**
     * Overridden to add currency schema
     * by default to all product types
     */
    render() {
        return (
            <>
                { super.render() }
                { this.renderSchema() }
            </>
        );
    }
}

export default ProductPriceComponent;
