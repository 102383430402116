/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

import { GTM_EVENT_KEY_USER_LOGIN, GTM_EVENT_KEY_USER_REGISTER } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/User/fireUserLoginEvent */
export const fireUserLoginEvent = () => {
    const {
        events: {
            gtm_user_login
        } = {}
    } = getStore().getState().ConfigReducer?.gtm;

    if (gtm_user_login) {
        const execute = debounceCallback(async () => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_USER_LOGIN
            });
        });

        execute();
    }
};

/** @namespace Scandiweb/Gtm/Event/User/fireUserRegisterEvent */
export const fireUserRegisterEvent = () => {
    const {
        events: {
            gtm_user_register
        } = {}
    } = getStore().getState().ConfigReducer?.gtm;

    if (gtm_user_register) {
        const execute = debounceCallback(async () => {
            pushToDataLayer({
                event: GTM_EVENT_KEY_USER_REGISTER
            });
        });

        execute();
    }
};
