/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { PaymentMethodType } from 'Type/Checkout.type';

import './PaypalPayment.style';

/** @namespace Scandiweb/PaypalExpress/Component/PaypalPayment/Component */
export class PaypalPaymentComponent extends PureComponent {
    static propTypes = {
        isSelected: PropTypes.bool,
        method: PaymentMethodType.isRequired,
        paymentAcceptanceMarkImageUrl: PropTypes.string,
        handleClick: PropTypes.func.isRequired,
        handleAcceptanceMarkClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        isSelected: false,
        paymentAcceptanceMarkImageUrl: ''
    };

    renderLabel() {
        const {
            method: { title } = {},
            paymentAcceptanceMarkImageUrl
        } = this.props;

        return (
            <span
              block="PaypalPayment"
              elem="Label"
            >
                <img
                  block="PaypalPayment"
                  elem="Image"
                  src={ paymentAcceptanceMarkImageUrl }
                  alt={ __('Acceptance Mark') }
                />
                <span>{ title }</span>
            </span>
        );
    }

    renderWhatIsPaypal() {
        const {
            handleAcceptanceMarkClick
        } = this.props;

        return (
            <button
              block="PaypalPayment"
              elem="WhatIsPaypal"
              onClick={ handleAcceptanceMarkClick }
            >
                { __('What is PayPal?') }
            </button>
        );
    }

    render() {
        const {
            isSelected,
            method: { title } = {},
            handleClick
        } = this.props;

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li
              block="CheckoutPayment"
              mix={ { block: 'PaypalPayment' } }
            >
                <button
                  block="CheckoutPayment"
                  mix={ { block: 'PaypalPayment', elem: 'Button' } }
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ handleClick }
                >
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: `option-${title}`,
                          name: `option-${title}`,
                          checked: isSelected
                      } }
                      isDisabled={ false }
                    />
                    { this.renderLabel() }
                </button>
                { this.renderWhatIsPaypal() }
            </li>
        );
    }
}

export default PaypalPaymentComponent;
