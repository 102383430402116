import { Field } from 'Util/Query';

export function getCanonical() {
    const fields = new Field('mw_canonical_url')
        .addFieldList([
            'url',
            'code'
        ]);

    return fields;
}

const getHreflangTagsItems = () => new Field('items').addFieldList(['url', 'code']);

export function getHreflangTags() {
    return new Field('mw_hreflangs')
        .addFieldList([
            getHreflangTagsItems()
        ]);
}

const _getProductInterfaceFields = (args, callback) => [
    ...callback(...args),
    'product_seo_name',
    getCanonical(),
    getHreflangTags()
];

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields

        }
    }
};
