/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyShopbyBrand/Query/BrandsConfig/Query */
export class BrandsConfigQuery {
    getQuery() {
        return new Field('availableStores')
            .addFieldList(this.getStoreConfigFields());
    }

    getStoreConfigFields() {
        return [
            'amshopby_brand_general_topmenu_enabled',
            'amshopby_brand_general_brands_popup',
            'amshopby_brand_general_menu_item_label',
            'amshopby_brand_general_attribute_code',
            'amshopby_brand_product_page_display_brand_image',
            'amshopby_brand_general_brands_page',
            'amshopby_brand_product_page_display_title',
            'amshopby_brand_product_page_display_description',
            'amshopby_brand_product_page_width',
            'amshopby_brand_product_page_height',
            'amshopby_brand_listing_brand_logo_width',
            'amshopby_brand_listing_brand_logo_height',
            'amshopby_brand_product_listing_settings_show_on_listing'
        ];
    }
}

export default new BrandsConfigQuery();
