/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyShopbyBrand/Query/BrandsList/Query/BrandsListQuery */
export class BrandsListQuery {
    getQuery() {
        return new Field('ambrandlist')
            .addFieldList(this.getListFields());
    }

    getListFields() {
        return [
            'all_letters',
            this.getBrandAttributesFields(),
            'display_zero',
            'filter_display_all',
            'image_height',
            'image_width',
            this.getItemsFields(),
            'show_count',
            'show_filter',
            'show_images',
            'show_search'
        ];
    }

    getBrandAttributesFields() {
        return [
            'attribute_code',
            this.getAttributeOptions(),
            'attribute_type',
            'entity_type',
            'input_type',
            this.getStorefrontProperties()
        ];
    }

    getAttributeOptions() {
        return [
            'label',
            'value'
        ];
    }

    getStorefrontProperties() {
        return [
            'position',
            'use_in_layered_navigation',
            'use_in_product_listing',
            'use_in_search_results_layered_navigation',
            'visible_on_catalog_pages'
        ];
    }

    getItemsFields() {
        return new Field('items')
            .addFieldList(this.getItemFields());
    }

    getItemFields() {
        return [
            'alt',
            'brandId',
            'cnt',
            'description',
            'image',
            'label',
            'letter',
            'short_description',
            'url'
        ];
    }
}

export default new BrandsListQuery();
