import { appendWithStoreCode } from 'Util/Url';

export const _getCategoryMeta = (args) => {
    const [category] = args;
    const {
        description,
        name,
        canonical_url,
        meta_title,
        meta_keywords,
        meta_description,
        meta_robots = 'follow, index',
        image
    } = category;

    const { location: { origin } } = window;
    const updatedCanonical = canonical_url && canonical_url.includes(origin)
        ? canonical_url : `${origin}${appendWithStoreCode(canonical_url)}`;

    return {
        description: meta_description || description,
        title: meta_title || name,
        keywords: meta_keywords,
        canonical_url: updatedCanonical,
        og_image: image,
        robots: meta_robots
    };
};

export const _getProductMeta = (args) => {
    const [product] = args;
    const {
        name,
        meta_title,
        meta_keyword,
        canonical_url,
        meta_description,
        image: { url: imageUrl = '' } = {}
    } = product;

    const { location: { origin } } = window;

    const updatedCanonical = canonical_url && canonical_url.includes(origin)
        ? canonical_url : `${origin}${appendWithStoreCode(canonical_url)}`;

    return {
        description: meta_description,
        keywords: meta_keyword,
        title: meta_title || name,
        canonical_url: updatedCanonical,
        og_image: imageUrl
    };
};

export default {
    'Store/Meta/Dispatcher': {
        'member-function': {
            _getCategoryMeta,
            _getProductMeta
        }
    }
};
