/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */

import { lazy } from 'react';

export const AllBrandsPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "brands-page" */ '../../route/AllBrandsPage'
));

export const TYPE_ALL_BRANDS = 'ALL_BRANDS_PAGE';

const addBrandsToTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite: {
            id,
            name
        }
    } = instance.props;

    switch (instance.getType()) {
    case TYPE_ALL_BRANDS:
        return {
            brandId: id,
            brandName: name
        };
    default:
        return callback(...args);
    }
};

const addBrandRouteToRender = (args, callback, instance) => {
    const {
        props,
        type
    } = instance.props;

    if (type === TYPE_ALL_BRANDS) {
        return (
            <AllBrandsPage
              { ...props }
            />
        );
    }

    return callback(...args);
};

export default {
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps: addBrandsToTypeSpecificProps
        }
    },
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent: addBrandRouteToRender
        }
    }
};
