import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';

export const CATEGORY = 'CATEGORY';
export const PRODUCT = 'PRODUCT';
export const CMS_PAGE = 'CMS_PAGE';

export const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        seoConfigurationData: state.ConfigReducer.seoConfigurationData,
        header_logo_src: state.ConfigReducer.header_logo_src,
        base_link_url: state.ConfigReducer.base_link_url,
        locale: state.ConfigReducer.code,
        og_image: state.MetaReducer.og_image,
        sw_hreflang: state.MetaReducer.sw_hreflang,
        type: state.UrlRewritesReducer.urlRewrite.type
    };
};

/** @namespace SeoSuite/Plugins/MetaContainerPlugin */
export class MetaContainerPlugin {
    containerProps=(args, callback, instance) => {
        const { seoConfigurationData } = instance.props;

        return {
            ...callback(...args),
            seoConfigurationData
        };
    };

    _generateOgMetaFromMetadata=(metadata, param = 'property') => Object
        .entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);

    _getOgType = (props) => {
        const { type } = props;

        switch (type) {
        case CATEGORY:
            return 'product.group';
        case PRODUCT:
            return 'product.item';
        default:
            return 'website';
        }
    };

    _getOgImage = (props) => {
        const {
            header_logo_src,
            og_image: ogImage = ''
        } = props;

        const logoSrc = media(header_logo_src, LOGO_MEDIA);

        if (!ogImage || ogImage.includes('placeholder')) {
            return logoSrc;
        }

        return ogImage;
    };

    _getLocale = (props) => {
        const { locale } = props;

        return locale;
    };

    _shouldRenderOg = (props) => {
        const {
            type,
            seoConfigurationData: {
                og_enabled_product,
                og_enabled_category,
                og_enabled_cms,
                og_enabled_website
            } = {}
        } = props;

        switch (type) {
        case CATEGORY:
            return og_enabled_category;
        case PRODUCT:
            return og_enabled_product;
        case CMS_PAGE:
            return og_enabled_cms;
        default:
            return og_enabled_website;
        }
    };

    _getMetadata = (args, callback, instance) => {
        const { location: { href } } = window;
        const { props } = instance;

        const ogMeta = {
            'og:title': instance._getTitle(),
            'og:type': this._getOgType(props),
            'og:description': instance._getDescription(),
            'og:url': href,
            'og:locale': this._getLocale(props),
            'og:image': this._getOgImage(props)
        };

        if (this._shouldRenderOg(props)) {
            return [...callback(...args), ...this._generateOgMetaFromMetadata(ogMeta)];
        }

        return callback(...args);
    };
}

export const { _getMetadata } = new MetaContainerPlugin();

export default {
    'Component/Meta/Container': {
        'member-function': {
            _getMetadata
        }
    },
    'Component/Meta/Container/mapStateToProps': {
        function: [
            {
                position: 50,
                implementation: mapStateToProps
            }
        ]
    }
};
