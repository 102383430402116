export const renderMeta = (args, callback, instance) => {
    const { metadata } = instance.props;

    return (
        <>
            { instance.renderTitle() }
            { instance.renderCanonical() }
            { metadata.map((tag) => {
                const {
                    name = null,
                    property = null,
                    content = null
                } = tag;

                return (
                    <meta
                      key={ name || property }
                      name={ name }
                      property={ property }
                      content={ content }
                    />
                );
            }) }
        </>
    );
};

export default {
    'Component/Meta/Component': {
        'member-function': {
            renderMeta
        }
    }
};
