import CheckmarkIcon from 'Component/CheckmarkIcon';
import {
    Field as SourceField
} from 'SourceComponent/Field/Field.component';

import { FIELD_TYPE } from './Field.config';

import './Field.override.style';

/** @namespace Scandipwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    /*
     * New array to render values
     */
    shouldRenderCheckmarkIconArray = [
        FIELD_TYPE.email,
        FIELD_TYPE.text,
        FIELD_TYPE.password,
        FIELD_TYPE.tel,
        FIELD_TYPE.number
    ];

    /**
     * Overridden to add mods type to separate styles for radio, checkboxes from other fields
     */
    renderErrorMessages() {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name },
            type
        } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return null;
        }

        const { errorMessages = [] } = validationResponse;

        if (!errorMessages) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages" mods={ { type } }>
                { errorMessages.map((message, index) => this.renderErrorMessage.call(this, message, name + index)) }
            </div>
        );
    }

    /*
     * Overridden to render checkmarkicon
     */
    render() {
        const {
            type, validationResponse, mix
        } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;

        const shouldRenderCheckmarkIcon = this.shouldRenderCheckmarkIconArray.includes(type);

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: !hasError && validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel() }
                    { shouldRenderCheckmarkIcon && <CheckmarkIcon /> }
                    { inputRenderer && inputRenderer() }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
