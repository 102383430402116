import {
    ProductReviewRating as SourceProductReviewRating
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.override.style';

/** @namespace Scandipwa/Component/ProductReviewRating/Component */
export class ProductReviewRatingComponent extends SourceProductReviewRating {
}

export default ProductReviewRatingComponent;
