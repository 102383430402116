import {
    CategoryDetails as SourceCategoryDetails
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';

import './CategoryDetails.override.style.scss';

/** @namespace Scandipwa/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetails {
    /*
    * Overriden to add CmsPage class for proper styling from page builder
     */
    render() {
        return (
            <article block="CategoryDetails" mix={ { block: 'CmsPage' } }>
                <div block="CategoryDetails" elem="Description">
                    { this.renderCategoryName() }
                    { this.renderCategoryDescription() }
                </div>
                { this.renderCategoryImage() }
            </article>
        );
    }
}

export default CategoryDetailsComponent;
