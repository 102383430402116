/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { ONE_DAY } from 'Util/Compare';
import { getIndexedProduct } from 'Util/Product';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import BrandsMoreFromBrandQuery from '../../query/BrandsMoreFromBrand.query';
import MoreFromBrandComponent from './MoreFromBrand.component';

/** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container */
export class MoreFromBrandContainer extends DataContainer {
    containerFunctions = {
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            title: '',
            items: []
        };
    }

    componentDidUpdate(prevProps) {
        const { productId } = this.props;
        const { productId: prevProductId } = prevProps;

        if (prevProductId !== productId) {
            this.fetchMoreFromBrandData();
        }
    }

    containerProps() {
        const { title } = this.state;

        return {
            title,
            items: this.getIndexedItems()
        };
    }

    getIndexedItems() {
        const { items } = this.state;

        return items.map(getIndexedProduct);
    }

    async fetchMoreFromBrandData() {
        const { productId } = this.props;
        const queries = [BrandsMoreFromBrandQuery.getQuery({ productId })];

        // use execute get to avoid caching issues
        try {
            const {
                amMoreFromThisBrand: {
                    title,
                    items
                }
            } = await executeGet(
                prepareQuery(queries),
                'MoreFromBrands',
                ONE_DAY
            );

            this.setState({
                title,
                items
            });
        } catch (e) {
            showNotification(
                'error',
                __('Error fetching MoreFromBrands!'),
                e
            );
        }
    //         .then(
    //             /** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/executeGet/then */
    //             ({ amMoreFromThisBrand: { title, items } = {} }) => this.setState({
    //                 title,
    //                 items
    //             })
    //         )
    //         .catch(
    //             /** @namespace Scandiweb/AmastyShopbyBrand/Component/MoreFromBrand/Container/executeGet/then/catch */
    //             (e) => showNotification('error', __('Error fetching MoreFromBrands!'), e)
    //         );
    // }
    }

    render() {
        return (
            <MoreFromBrandComponent
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreFromBrandContainer);
