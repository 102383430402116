/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
import {
    AddIcon as SourceAddIcon
} from 'SourceComponent/StarIcon/StarIcon.component';

import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from './StarIcon.config';

import './StarIcon.override.style';

/** @namespace Scandipwa/Component/StarIcon/Component */
export class StarIconComponent extends SourceAddIcon {
    /*
     * Overridden to change path based on design
     */
    getStarPath() {
        const { starFill } = this.props;

        if (starFill === STAR_EMPTY) {
            return (
                <path
                  block="StarIcon"
                  elem="EmptyStar"
                  d="M8.46415 0.406611C8.63415 -0.135491 9.36585 -0.135492 9.53585 0.40661L11.288 5.99389C11.3642 6.23696 11.581 6.40105 11.8252 6.40046L17.4336 6.38705C17.9783 6.38574 18.2082 7.11442 17.77 7.45368L13.2346 10.9652C13.0399 11.116 12.9587 11.38 13.0327 11.6215L14.7501 17.2255C14.9166 17.7688 14.3246 18.2195 13.883 17.8857L9.33 14.4434C9.13296 14.2944 8.86704 14.2944 8.67001 14.4434L4.11696 17.8857C3.67539 18.2195 3.08344 17.7688 3.24993 17.2255L4.96729 11.6215C5.04129 11.38 4.96006 11.116 4.76541 10.9652L0.229994 7.45368C-0.208189 7.11442 0.0216564 6.38574 0.56644 6.38705L6.17485 6.40046C6.41898 6.40105 6.63576 6.23696 6.71199 5.99389L8.46415 0.406611Z"
                />
            );
        }

        if (starFill === STAR_HALF_FULL) {
            return (
                <>
                    <path
                      block="StarIcon"
                      elem="EmptyStar"
                      d="M8.46415 0.406611C8.63415 -0.135491 9.36585 -0.135492 9.53585 0.40661L11.288 5.99389C11.3642 6.23696 11.581 6.40105 11.8252 6.40046L17.4336 6.38705C17.9783 6.38574 18.2082 7.11442 17.77 7.45368L13.2346 10.9652C13.0399 11.116 12.9587 11.38 13.0327 11.6215L14.7501 17.2255C14.9166 17.7688 14.3246 18.2195 13.883 17.8857L9.33 14.4434C9.13296 14.2944 8.86704 14.2944 8.67001 14.4434L4.11696 17.8857C3.67539 18.2195 3.08344 17.7688 3.24993 17.2255L4.96729 11.6215C5.04129 11.38 4.96006 11.116 4.76541 10.9652L0.229994 7.45368C-0.208189 7.11442 0.0216564 6.38574 0.56644 6.38705L6.17485 6.40046C6.41898 6.40105 6.63576 6.23696 6.71199 5.99389L8.46415 0.406611Z"
                    />
                    <g clipPath="url(#a)">
                        <path d="M8.464.788a.564.564 0 0 1 1.072 0l1.752 5.331a.564.564 0 0 0 .537.388l5.609-.013a.564.564 0 0 1 .336 1.018l-4.535 3.35a.564.564 0 0 0-.202.627l1.717 5.347a.564.564 0 0 1-.867.63L9.33 14.18a.564.564 0 0 0-.66 0l-4.553 3.285a.564.564 0 0 1-.867-.63l1.717-5.347a.564.564 0 0 0-.202-.627L.23 7.513a.564.564 0 0 1 .336-1.018l5.609.013c.244 0 .46-.156.537-.388L8.464.788Z" />
                    </g>
                    <clipPath id="a">
                        <path d="M0 0h9v18H0z" />
                    </clipPath>
                </>
            );
        }

        if (starFill === STAR_FULL) {
            return (
                <path d="M8.46415 0.406611C8.63415 -0.135491 9.36585 -0.135492 9.53585 0.40661L11.288 5.99389C11.3642 6.23696 11.581 6.40105 11.8252 6.40046L17.4336 6.38705C17.9783 6.38574 18.2082 7.11442 17.77 7.45368L13.2346 10.9652C13.0399 11.116 12.9587 11.38 13.0327 11.6215L14.7501 17.2255C14.9166 17.7688 14.3246 18.2195 13.883 17.8857L9.33 14.4434C9.13296 14.2944 8.86704 14.2944 8.67001 14.4434L4.11696 17.8857C3.67539 18.2195 3.08344 17.7688 3.24993 17.2255L4.96729 11.6215C5.04129 11.38 4.96006 11.116 4.76541 10.9652L0.229994 7.45368C-0.208189 7.11442 0.0216564 6.38574 0.56644 6.38705L6.17485 6.40046C6.41898 6.40105 6.63576 6.23696 6.71199 5.99389L8.46415 0.406611Z" />
            );
        }

        return null;
    }

    /*
     * Overridden to change view box to 18
     */
    render() {
        return (
            <svg
              block="StarIcon"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                { this.getStarPath() }
            </svg>
        );
    }
}

export default StarIconComponent;
