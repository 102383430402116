/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrandSlider from '../../component/BrandSlider';
import { BRAND_SLIDER_LU_TYPE } from '../../component/BrandSlider/BrandSlider.config';
import BrandsList from '../../component/BrandsList';
import { BRAND_LIST_LU_TYPE } from '../../component/BrandsList/BrandList.config';

const renderBrandWidgets = (member) => ({
    ...member,
    [BRAND_SLIDER_LU_TYPE]: BrandSlider,
    [BRAND_LIST_LU_TYPE]: BrandsList
});

export default {
    'Scandiweb/LayoutUpdates/Component/Widget/Component/WidgetComponent': {
        'member-property': {
            typeToRenderMap: renderBrandWidgets
        }
    }
};
