/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getSortedItemsByPosition } from 'Util/Menu';
import { appendWithStoreCode } from 'Util/Url';

import BrandMenuOverlay from '../../component/BrandMenuOverlay';
import { getCurrentStoreBrandsConfig } from '../../util/Brands';

export const BRANDS_MENU_ITEM_DEFAULT_POS = 1;
export const BRANDS_MENU_ITEM_TITLE = 'Brands';
export const BRANDS_MENU_ITEM_URL = '/brands';

export const BRAND_LINK_POSITION_MAP = {
    1: '1',
    2: '1000'
};

const addBrandsConfigToMapStateToProps = (args, callback) => {
    const [state] = args;
    return {
        ...callback(...args),
        config: state.ConfigReducer.brandsConfig,
        storeCode: state.ConfigReducer.code
    };
};

export class MenuPlugin {
    addConfigToContainerProps = (args, callback, instance) => {
        const { config, storeCode } = instance.props;

        if (config && storeCode) {
            return {
                ...callback(...args),
                config,
                storeCode
            };
        }

        if (config) {
            return {
                ...callback(...args),
                config
            };
        }

        if (storeCode) {
            return {
                ...callback(...args),
                storeCode
            };
        }

        return {
            ...callback(...args)
        };
    };

    getMenuItemPosition(positionCode) {
        return BRAND_LINK_POSITION_MAP[positionCode] || 1;
    }

    addMenuOverlayToRenderFirstLevelItems = (args, callback, instance) => {
        const {
            device,
            storeCode,
            activeMenuItemsStack,
            config: {
                availableStores = []
            } = {}
        } = instance.props;
        const item = Object.assign({}, ...args);
        const { title } = item;

        if (!availableStores.length) {
            return callback(args);
        }

        const {
            amshopby_brand_general_menu_item_label,
            amshopby_brand_general_topmenu_enabled,
            amshopby_brand_general_brands_popup
        } = getCurrentStoreBrandsConfig(availableStores, storeCode);

        const itemPostion = this.getMenuItemPosition(amshopby_brand_general_topmenu_enabled);

        const overlayVisible = activeMenuItemsStack[0] === itemPostion;

        const isBrandMenuOverlayRendered = title === amshopby_brand_general_menu_item_label
        && !device.isMobile
        && amshopby_brand_general_brands_popup;

        const isDisplayLast = itemPostion > 1;

        if (isBrandMenuOverlayRendered) {
            return (
                <>
                    { callback(...args) }
                    <BrandMenuOverlay
                      isVisible={ overlayVisible }
                      isDisplayLast={ isDisplayLast }
                    />
                </>
            );
        }

        return {
            ...callback(...args)
        };
    };

     addBrandMenuItem = (args, callback, instance) => {
         const {
             menu,
             storeCode,
             config: {
                 availableStores = []
             } = {}
         } = instance.props;

         if (!availableStores.length) {
             return callback(args);
         }

         const {
             amshopby_brand_general_menu_item_label,
             amshopby_brand_general_topmenu_enabled,
             amshopby_brand_general_brands_page
         } = getCurrentStoreBrandsConfig(availableStores, storeCode);

         // eslint-disable-next-line eqeqeq
         if (amshopby_brand_general_topmenu_enabled == '0') {
             return callback(args);
         }

         const linkPosition = this.getMenuItemPosition(amshopby_brand_general_topmenu_enabled);

         const categoryArray = Object.values(menu);
         if (!categoryArray.length) {
             return null;
         }

         const [{ children, title: mainCategoriesTitle }] = categoryArray;

         const newItem = {
             10: {
                 children: {},
                 icon: '',
                 is_active: true,
                 item_class: '',
                 item_id: `${linkPosition}`,
                 parent_id: 1,
                 isLink: true,
                 position: linkPosition,
                 title: amshopby_brand_general_menu_item_label || BRANDS_MENU_ITEM_TITLE,
                 url: {
                     pathname: appendWithStoreCode(amshopby_brand_general_brands_page),
                     search: ''
                 }
             }
         };

         const newChildren = { ...children, ...newItem };
         const childrenArray = getSortedItemsByPosition(Object.values(newChildren));

         return (
             <>
                  <div block="Menu" elem="MainCategories">
                      <ul
                        block="Menu"
                        elem="ItemList"
                        mods={ { type: 'main' } }
                        aria-label={ mainCategoriesTitle }
                      >
                          { childrenArray.map(instance.renderFirstLevel.bind(instance)) }
                      </ul>
                      { instance.renderAdditionalInformation(true) }
                  </div>
                  { instance.renderSubMenuDesktop(children) }
             </>
         );
     };
}

export const { addBrandMenuItem, addMenuOverlayToRenderFirstLevelItems, addConfigToContainerProps } = new MenuPlugin();

export default {
    'Component/Menu/Container/mapStateToProps': {
        function: addBrandsConfigToMapStateToProps
    },
    'Component/Menu/Container': {
        'member-function': {
            containerProps: addConfigToContainerProps
        }
    },
    'Component/Menu/Component': {
        'member-function': {
            renderTopLevel: addBrandMenuItem,
            renderFirstLevelItems: addMenuOverlayToRenderFirstLevelItems
        }
    }
};
