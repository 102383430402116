import ProductListQuery from 'Query/ProductList.query';
import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    /*
     * Overridden to add _getPriceRangeField, which is required to add discount to the cart price
     */
    _getProductField() {
        return new Field('product')
            .addFieldList(
                [
                    ...ProductListQuery._getCartProductInterfaceFields(),
                    ProductListQuery._getPriceRangeField()
                ]
            );
    }
}

export default new CartQuery();
