import { GET_CMS_CANONICAL_URL } from './SeoBase.action';

export const initialState = {
    mwCanonicalUrl: {}
};

/** @namespace SeoSuite/Store/SeoBase/Reducer/SeoBaseReducer */
export const SeoBaseReducer = (state = initialState, action) => {
    const { type, data } = action;

    switch (type) {
    case GET_CMS_CANONICAL_URL:
        const {
            cmsPage: {
                mw_canonical_url
            }
        } = data;

        return {
            ...state,
            mwCanonicalUrl: mw_canonical_url
        };

    default:
        return state;
    }
};

export default SeoBaseReducer;
