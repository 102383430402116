/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';

import BrandsUrlResolverQuery from '../../query/BrandsUrlResolver.query';

const onSuccess = (args) => {
    const [{ brandUrlResolver, urlResolver }, dispatch, { urlParam }] = args;

    dispatch(
        updateUrlRewrite(
            brandUrlResolver || urlResolver || { notFound: true },
            urlParam
        )
    );
};

const prepareRequest = (args, callback, instance) => {
    const [options] = args;
    const { urlParam } = instance.processUrlOptions(options);
    return [
        ...callback(...args),
        BrandsUrlResolverQuery.getQuery(urlParam)
    ];
};

export const config = {
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            onSuccess,
            prepareRequest
        }
    }
};

export default config;
