/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import BrandsConfigQuery from '../../query/BrandsConfig.query';
import BrandsListQuery from '../../query/BrandsList.query';
import BrandsSliderQuery from '../../query/BrandsSlider.query';

const addBrandsStoreConfigToRequest = (args, callback) => ([
    ...callback(...args),
    BrandsConfigQuery.getQuery(),
    BrandsSliderQuery.getQuery(),
    BrandsListQuery.getQuery()
]);

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addBrandsStoreConfigToRequest
        }
    }
};
