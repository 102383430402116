/* eslint-disable no-magic-numbers */
/* eslint-disable no-bitwise */
/* eslint-disable fp/no-let */
/* eslint-disable padding-line-between-statements */
/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

// eslint-disable-next-line header/header
import { isSignedIn } from 'Util/Auth';
import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import { DL_VAL_NOT_APPLICABLE } from './common';

export const DL_VAL_USER_LOGIN = 'Logged in';
export const DL_VAL_USER_LOGOUT = 'Logged out';

/** @namespace Scandiweb/Gtm/Data/Customer/useHash */
export const useHash = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

/** @namespace Scandiweb/Gtm/Data/Customer/getCustomerData */
export const getCustomerData = async () => {
    if (!isSignedIn()) {
        // user is not signed in => return default object
        return {
            userLoginState: DL_VAL_USER_LOGOUT,
            customerId: DL_VAL_NOT_APPLICABLE,
            userExistingCustomer: DL_VAL_NOT_APPLICABLE,
            userLifetimeValue: 0,
            userLifetimeOrders: 0
        };
    }

    // vvv Wait for customer id, if customer is logged in
    await waitForCallback(() => getStore().getState().MyAccountReducer?.customer?.id);

    const {
        MyAccountReducer: {
            customer: {
                id,
                email,
                userExistingCustomer,
                userLifetimeValue,
                userLifetimeOrders
            } = {}
        } = {}
    } = getStore().getState();

    const hashedEmail = useHash(email);

    return {
        userLoginState: DL_VAL_USER_LOGIN,
        customerId: id || 0,
        customerEmail: hashedEmail || '',
        // vvv this fields are added via plugins
        userExistingCustomer: userExistingCustomer || DL_VAL_NOT_APPLICABLE,
        userLifetimeValue: userLifetimeValue || 0,
        userLifetimeOrders: userLifetimeOrders || 0
    };
};
