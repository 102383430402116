/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isInitiallySignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

const holdCustomerData = () => {
    const signedIn = isInitiallySignedIn();
    const customer = () => {
        if (signedIn) {
            return BrowserDatabase.getItem('customer');
        }

        return {};
    };

    return ({
        isSignedIn: signedIn,
        passwordResetStatus: false,
        isPasswordForgotSend: false,
        isLoading: false,
        isLocked: false,
        customer: customer(),
        message: ''
    });
};

export default {
    'Store/MyAccount/Reducer/getInitialState': {
        function: holdCustomerData
    }
};
